import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { envelopIcon } from '@progress/kendo-svg-icons';
import { Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, throttleTime } from 'rxjs/operators';
import { IPortalOrderService } from 'src/app/portal-order/services/portal-orders.service.interface';
import { orderSummaryFormName, orderSummaryLineColumnsFormName } from 'src/app/shared/constants/form-names.consts';
import { CrmAccount } from 'src/app/shared/models/account';
import { CosmosCustomer } from 'src/app/shared/models/customer';
import { FormConfiguration } from 'src/app/shared/models/form-configuration';
import { KendoGridBase } from 'src/app/shared/models/kendo-grid-base';
import { PortalOrder } from 'src/app/shared/models/order-creation/portal-order';
import { PortalOrderProduct } from 'src/app/shared/models/order-creation/portal-order-product';
import { PriceListResponse } from 'src/app/shared/models/pricelists/pricelist-response';
import { ColumnSettings } from 'src/app/shared/models/ui/column-settings';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { SecurityService } from 'src/app/shared/security/security.service';
import { ICustomerService } from 'src/app/shared/services/customer/customer.service.interface';
import { KendoAlertService } from 'src/app/shared/services/kendo-alerts.service';
import { IPricelistService } from 'src/app/shared/services/pricelist/pricelist.service.interface';
import { CompanyAdminConfigurationStore } from 'src/app/shared/stores/company-admin-configuration.store';
import { Utils } from 'src/app/shared/utils';
import { OrderLineColumns } from "./order-line-columns";

@Component({
  selector: 'ntw-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss', './../../shared/styles/button.scss']
})
export class OrderSummaryComponent extends KendoGridBase<PortalOrderProduct> implements OnInit {
  @Input() portalOrder: PortalOrder;
  @Output() portalOrderChange = new EventEmitter<PortalOrder>();

  public emitPortalOrderChange(value: PortalOrder) {
    this.portalOrderChange.emit(value);
  }

  @Input() pricesUpdatedOn: Date | undefined;
  @Output() getPricesStarted = new EventEmitter();
  @Output() getPricesFinished = new EventEmitter();
  formConfig: FormConfiguration;
  columnsFormConfig: FormConfiguration;

  public columnSettings: ColumnSettings[];

  currentAccount: CrmAccount;
  currentCustomer: CosmosCustomer;

  public firstRow: TitleValue[] = [];
  public secondRow: TitleValue[] = [];

  private latestPriceListResponse: PriceListResponse;

  @Input() isEverythingSelected: boolean;
  @Input() isCompanyNorway: boolean;
  public isDetailsStepValid = false;

  public isTermsAndConditionsAccepted = false;
  @Output() submitOrderClicked = new EventEmitter();
  @Output() missingOrderFields = new EventEmitter();
  private submitOrderSubject: Subject<void> = new Subject<void>();

  @Input() termsAndConditionsPath: string;
  public pdfViewerDialogVisible = false;

  public openPdfViewerDialog = () => {
    this.pdfViewerDialogVisible = true;
  }

  public closePdfViewerDialog = () => {
    this.pdfViewerDialogVisible = false;
  }

  public icons = {
    envelope: envelopIcon,
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyAdminConfigurationStore: CompanyAdminConfigurationStore,
    private customerService: ICustomerService,
    private kendoAlertService: KendoAlertService,
    private portalOrdersService: IPortalOrderService,
    private pricelistService: IPricelistService,
    private securityService: SecurityService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.companyAdminConfigurationStore.getFormConfiguration(orderSummaryFormName).pipe(concatMap(formConfig => {
      this.formConfig = formConfig;
      return this.companyAdminConfigurationStore.getFormConfiguration(orderSummaryLineColumnsFormName);
    }), concatMap(columnsFormConfig => {
      this.columnsFormConfig = columnsFormConfig;
      return this.securityService.getCurrentAccount();
    }), concatMap(currentAccount => {
      this.currentAccount = currentAccount;
      return this.customerService.getCustomerByCode(this.currentAccount.ntw_axcode);
    }), concatMap(currentCustomer => {
      this.currentCustomer = currentCustomer;
      this.getEntities();
      this.columnSettings = FormConfiguration.applyToColumnSettings(this.columnsFormConfig, [...OrderLineColumns]);
      this.getPricesForProducts().subscribe();
      return this.activatedRoute.paramMap;
    }), catchError(error => {
      this.kendoAlertService.showErrorAlert(error);
      return of(null);
    })).subscribe();

    this.submitOrderSubject.pipe(
      throttleTime(2000)
    ).subscribe(() => {
      this.submitOrderLogic();
    });
  }

  public getEntities() {
    this.entities = this.portalOrder.orderProducts;
    this.generateGridDataView();
    this.portalOrdersService.calculateFieldsFromProducts(this.portalOrder);
    this.fillRowsWithData();
  }


  /**
   * Retrieves prices for the products in the order.
   * If prices are already available and not expired, it returns the prices immediately.
   * Otherwise, it fetches the prices from the server and assigns them to the products.
   *
   * @returns An Observable that emits a boolean value indicating whether the prices were updated.
   */
  getPricesForProducts(): Observable<boolean> {
    const updateAfterMilliseconds = 5 * 60 * 1000; //5 minutes

    const isAnyProductWithoutPrice = this.portalOrder.orderProducts.some(x => x.lineAmount === undefined);
    const arePricesExpired = !this.pricesUpdatedOn || Date.now() - this.pricesUpdatedOn.getTime() > updateAfterMilliseconds;

    if (!isAnyProductWithoutPrice && !arePricesExpired) {
      return of(false);
    }

    this.loading = true;
    this.getPricesStarted.emit();
    const products = this.portalOrder.orderProducts;
    const customerAxCode = this.securityService.getCurrentAccountObject()?.ntw_axcode;

    return this.customerService.getCustomerByCode(customerAxCode).pipe(
      concatMap(customer => {
        return this.pricelistService.getPriceForMultipleProducts(products, customer);
      }),
      concatMap(pricelistResponse => {
        const pricesUpdated = this.assignPricesToProducts(products, pricelistResponse);
        this.latestPriceListResponse = pricelistResponse;
        this.getPricesFinished.emit();
        this.loading = false;
        return of(pricesUpdated);
      }),
      catchError(error => {
        this.kendoAlertService.showErrorAlert("alerts.pricesError");
        console.error(error);
        this.loading = false;
        throw error;
      }));
  }

  /**
   * Assigns prices to products based on the given price response.
   * If prices are not found in the response, an error alert is shown.
   * Calculates product prices and updates the portal order and rows with the new data.
   *
   * @param products - The array of PortalOrderProduct objects.
   * @param priceResponse - The PriceListResponse object containing the price items.
   * @returns A boolean indicating whether the prices were updated.
   */
  assignPricesToProducts(products: PortalOrderProduct[], priceResponse: PriceListResponse): boolean {
    if (!priceResponse.items || priceResponse.items.length == 0) {
      this.kendoAlertService.showErrorAlert("alerts.pricesNotFound");
      throw new Error("alerts.pricesNotFound");
    }
    let pricesUpdated = false;
    priceResponse.items.forEach(priceItem => {
      const product = products.find(p => p.axCode === priceItem.id);
      if (!product) {
        return;
      }
      if (!priceItem.errorMsg) {
        const oldLineAmount = product.lineAmount;
        PortalOrderProduct.calculateProductPrice(product, priceItem);
        pricesUpdated = pricesUpdated || oldLineAmount !== product.lineAmount;
      }
      this.portalOrdersService.calculateFieldsFromProducts(this.portalOrder);
      this.fillRowsWithData();
    });
    return pricesUpdated;
  }

  public fillRowsWithData() {
    const fields: TitleValue[] = [
      {
        field: "totalValue", title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValue"), this.currentCustomer),
        value: Utils.FormatNumber(this.portalOrder.totalValue, 2), subtitle: ''
      },
      {
        field: "totalValueWithVat", title: Utils.TryAddCurrencyCode(this.translateService.instant("portalOrder.totalValueWithVat"), this.currentCustomer),
        value: Utils.FormatNumber(this.portalOrder.totalValueWithVat, 2), subtitle: ''
      },
      {
        field: "totalVolumeLiters", title: "portalOrder.totalVolumeLiters",
        value: Utils.FormatNumber(this.portalOrder.totalVolumeLiters, 2), subtitle: ''
      },
      {
        field: "totalNetWeight", title: "portalOrder.totalNetWeight",
        value: Utils.FormatNumber(this.portalOrder.totalNetWeight, 2), subtitle: ''
      },
      {
        field: "totalGrossWeight", title: "portalOrder.totalGrossWeight",
        value: Utils.FormatNumber(this.portalOrder.totalGrossWeight, 2), subtitle: ''
      },
      {
        field: "totalPalletQty", title: "portalOrder.totalPalletQty",
        value: Utils.FormatNumber(this.portalOrder.totalPalletQty, 2), subtitle: ''
      },
    ];

    const visibleFields = FormConfiguration.applyToTitleValues(this.formConfig, fields);

    this.firstRow = [];
    this.secondRow = [];

    if (visibleFields.length <= 4) {
      visibleFields.forEach(field => {
        this.firstRow.push({ title: field.title, value: field.value, subtitle: '' });
      });
    } else {
      visibleFields.slice(0, 3).forEach(field => {
        this.firstRow.push({ title: field.title, value: field.value, subtitle: '' });
      });
      visibleFields.slice(3).forEach(field => {
        this.secondRow.push({ title: field.title, value: field.value, subtitle: '' });
      });
    }
  }

  createFormGroup = (product: PortalOrderProduct) => {
    const quantity = product.quantity;

    const formGroup = new UntypedFormGroup({
      axCode: new UntypedFormControl(product.axCode),
      quantity: new UntypedFormControl(quantity, Validators.pattern("^[1-9][0-9]*")),
    });

    formGroup.get('quantity').valueChanges.subscribe((value) => {
      product.quantity = value;
      this.assignPricesToProducts([product], this.latestPriceListResponse);
      this.portalOrderChange.emit(this.portalOrder);
      this.generateGridDataView();
    });

    return formGroup;
  }

  public deleteProduct = (product: PortalOrderProduct) => {
    const index = this.portalOrder.orderProducts.indexOf(product);
    if (index > -1) {
      this.portalOrder.orderProducts.splice(index, 1);
      this.portalOrdersService.calculateFieldsFromProducts(this.portalOrder);
      this.portalOrderChange.emit(this.portalOrder);
      this.fillRowsWithData();
      this.generateGridDataView();
    }
  }

  public submitVisible(): boolean {
    return !this.isCompanyNorway;
  }

  public submitButtonEnabled(): boolean {
    const isSubmitVisible = this.submitVisible();
    const pricesObtained = -1 !== this.portalOrder?.orderProducts?.findIndex(x => x.lineAmount > 0);

    return isSubmitVisible && !this.loading && pricesObtained && this.isTermsAndConditionsAccepted;
  }

  public submitOrder() {
    this.submitOrderSubject.next();
  }

  private submitOrderLogic() {
    if (!this.isEverythingSelected) {
      this.kendoAlertService.showErrorAlert("alerts.missingOrderFields");
      this.missingOrderFields.emit();
      window.scrollTo(0, 0);
      return;
    }
    this.loading = true;
    this.getPricesForProducts().subscribe((pricesUpdated) => {
      if (!pricesUpdated) {
        this.submitOrderClicked.emit();
      }
      else {
        this.kendoAlertService.showInfoAlert("alerts.pricesUpdated");
      }
      this.loading = false;
    });
  }
}
